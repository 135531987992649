import {Helmet} from "react-helmet";

const MetaBlocks = ({app}) => {

  const title = app?.title || "My Fortuna App";
  const description = app?.description || "Another fantastic release from My Fortuna. Lets make the world a better place.";
  const keywords = app?.keywords || "My Fortuna, Fortuna, MyFortuna, FortunaApp, Fortuna App, MyFortunaApp, MyFortuna App";
  const url = app?.path ? `https://myfortuna.app${app.path}/` : `https://myfortuna.app/`;
  const thumbnail = app?.path ? `https://myfortuna.app/thumbnails${app.path}_og.jpg` : `https://myfortuna.app/thumbnails/generic_og.jpg`;

  return <Helmet>
    {/* check if noindex flag is true */}
    {app?.noindex && <meta name="robots" content="noindex" />}
    <meta charSet="utf-8" />
    <title>{title}</title>
    {/*description meta tag*/}
    <meta name="description" content={description}/>
    {/*keywords meta tag*/}
    <meta name="keywords" content={keywords}/>
    {/*link to site*/}
    <link rel="canonical" href={url} />
    {/*og meta tags - for social media posts*/}
    <meta property="og:title" content={title} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={thumbnail} />
    <meta property="og:description" content={description} />
    <meta name="twitter:card" content="summary_large_image"/>
    <meta property="og:site_name" content="My Fortuna Apps"/>
  </Helmet>
}
export default MetaBlocks;