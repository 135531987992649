import { Button } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify";
import { useTheme } from "@mui/material/styles";
import React from "react";

const LinkIconButton = ({
  link,
  icon,
  text,
  variant = "outlined",
  textStyle = {},
  iconStyle = {},
  onClick,
  disabled,
}) => {
  const theme = useTheme();
  return (
    <Button
      href={link}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      sx={{
        color: disabled ? "grey" : "white",
        borderColor: disabled ? "grey" : "white",
        "&:hover": {
          color: theme?.palette?.light?.main || "white",
        },
        //disabled
        "&.Mui-disabled": {
          color: "grey",
          borderColor: "lightgrey",
        },
      }}
    >
      <Icon icon={icon} style={{ ...iconStyle }} />
      <div style={{ marginLeft: ".5em", ...textStyle }}>{text || "Link"}</div>
    </Button>
  );
};

export default LinkIconButton;
