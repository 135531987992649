import MainTitleSubtitle from "../../generic/components/template/text/MainTitleSubtitle";
import MetaBlocks from "../../generic/components/views/MetaBlocks";
import Footer from "../../generic/components/views/Footer/Footer";
import AppList from "../../generic/components/views/AppList/AppList";
import React, { useState, useEffect } from "react";

const ListAppsPage = ({ pageTitle, tabTitle, subTitle, noindex = false }) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <div
      style={{
        backgroundImage: "radial-gradient(circle at 70% 20%, #602AFF, #0D084D)",
        minHeight: "100vh",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        color: "white",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <MetaBlocks
        app={{
          title: tabTitle,
          noindex,
        }}
      />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "50em",
          marginLeft: "1em",
          marginRight: "1em",
        }}
      >
        <MainTitleSubtitle
          titles={[pageTitle]}
          titleStyle={{ textAlign: "center" }}
        />
        <p>
          {subTitle || "You might want to explore some of MyFortuna's apps."}
        </p>
        <AppList />
      </div>
      <Footer />
    </div>
  );
};

export default ListAppsPage;
