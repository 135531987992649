import React, {useRef} from "react";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {useElementSize} from "../../../hooks/useElementSize";

const Grid = ({items, itemsPerRow = 3, itemsPerRowMobile =  2, minItemSizeEM, gridStyle={}, component}) => {

  const ref = useRef();
  const {width} = useElementSize({ref});


  //convert em to px
  const getItemsFittingInRow = (width) => {
    if(!minItemSizeEM){
      return 1000;
    }

    const itemMinSizePX = minItemSizeEM * parseFloat(getComputedStyle(document.documentElement).fontSize);
    return Math.floor(width / itemMinSizePX);
  }

  const {isMobile} = useWindowSize();
  itemsPerRow = isMobile ? itemsPerRowMobile : itemsPerRow;



  return <div ref={ref} style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${Math.min(getItemsFittingInRow(width), itemsPerRow)}, 1fr)`,
        gridAutoColumns: "auto",
        gap: "1em",
        margin: "1em",
        maxWidth: "100em",
        flex: 1,
        ...gridStyle,
      }}
    >
      {Array.isArray(items) && items.length > 0 && items.map((item, index) => {
        return React.cloneElement(component, {item});
      })}
    </div>
  </div>

}

export default Grid