import React, {useEffect, useState} from "react";

export const useDynamicImage = ({src}) => {
  const [currentImage, setCurrentImage] = useState(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const image = await import("../../apps/" + src);
        setCurrentImage(image.default);
      } catch (e) {
        console.error(e);
      }
    }

    getData();
  }, [src]);

  return currentImage;
};