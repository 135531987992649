import { TextField } from "@mui/material";

const ContactTextField = ({
  label,
  minRows,
  maxRows,
  style,
  multiline,
  onChange,
  required,
}) => {
  return (
    <TextField
      id="outlined-basic"
      label={label}
      variant="outlined"
      style={style}
      onChange={onChange}
      required={required}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white",
          },
          "&:hover fieldset": {
            borderColor: "cyan",
          },
          "&.Mui-focused fieldset": {
            borderColor: "cyan",
          },
        },
        //hint text
        "& .MuiFormLabel-root": {
          color: "white",
        },
        //hint tet focused label
        "& .Mui-focused .MuiFormLabel-root": {
          color: "white",
        },
        //label text
        "& .MuiInputLabel-root": {
          color: "white",
        },
        //input text
        "& .MuiInputBase-input": {
          color: "white",
        },
      }}
      minRows={minRows}
      maxRows={maxRows}
      multiline={multiline}
    />
  );
};

export default ContactTextField;
