import { mergeGenericAppConfig } from "../apps/mergeGenericAppConfig";
import LinkIconButton from "../generic/components/inputs/buttons/LinkIconButton";
import MetaBlocks from "../generic/components/views/MetaBlocks";

import Footer from "../generic/components/views/Footer/Footer";
import React from "react";

const PolicyPage = ({app, policyContent, policyName }) => {
  app = mergeGenericAppConfig({ app });
  const finalAppName = app?.name || "PicaBook";

  return (
    <div
      style={{
        backgroundImage: "radial-gradient(circle at 70% 20%, #602AFF, #0D084D)",
        minHeight: "100vh",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        color: "white",
        alignItems: "center",
      }}
    >
      <MetaBlocks 
        app={{
          noindex: true,
        }}
      />
      <div
        style={{
          maxWidth: "70em",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h2
            style={{ marginTop: "1em", marginRight: "1em" }}
          >{`${finalAppName} ${policyName}`}</h2>
          <div style={{ flex: 1 }} />
          <LinkIconButton
            icon="mdi:arrow-left"
            link={app?.path + "/"}
            text={"Go Back"}
          />
        </div>
        <hr style={{ marginBottom: "1em" }} />

        {React.cloneElement(policyContent, { appName: finalAppName })}
        <Footer app={app} />
      </div>
    </div>
  );
};

export default PolicyPage;
