import PhoneScreenshot from "../../views/PhoneScreenshot/PhoneScreenshot";

const ShowcaseDoublePivot = ({ screenshots }) => {
  return (
    <div style={{ width: "100%", display: "grid", overflow: "hidden" }}>
      <div
        style={{
          transform: "scale(.8) translate( 6em) rotate(15deg)",
          gridColumn: 1,
          gridRow: 1,
        }}
      >
        <PhoneScreenshot
          screenshot={screenshots[1].src}
          alt={screenshots[1].alt}
        />
      </div>
      <div
        style={{
          transform: "scale(.8) translate(-10em, 2em) rotate(-20deg) ",
          gridColumn: 1,
          gridRow: 1,
        }}
      >
        <PhoneScreenshot
          screenshot={screenshots[0].src}
          alt={screenshots[0].alt}
        />
      </div>
    </div>
  );
};

export default ShowcaseDoublePivot;
