import React from 'react';
import "./PhoneScreenshot.scss";
import commingSoonScreenshot from "../../../../apps/generic/assets/screenshots/comming_soon.png";
import {useDynamicImage} from "../../../hooks/useDynamicImage";

// REFERENCES:
// 1) css by Adiel Hercules - https://codepen.io/adielhercules/pen/dJJGBQ
const PhoneScreenshot = ({screenshot, alt, scale = 1}) => {

  const currentImage = useDynamicImage({src: screenshot});

  return <div className="phoneContainer" style={{transform: "scale(0.9)", display: "flex", justifyContent: "center"}}>
    <div
      className="iphone-x"
      style={{
        height: `${780 * scale}px`,
        width: `${360 * scale}px`,
      }}
    >
      {/*<i>Speaker</i>*/}
      {/*<b>Camera</b>*/}
      {/*<s>10:24</s>*/}
      {/*<span>Left action button</span>*/}
      {/*<span>Right action button</span>*/}
    </div>
    <img
      className="screenshot"
      alt={alt || "Coming Soon Screenshot"}
      src={currentImage || commingSoonScreenshot}
      style={{
        height: `${780 * scale}px`,
        width: `${360 * scale}px`,
      }}/>
  </div>
};

export default PhoneScreenshot;