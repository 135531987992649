import React from "react";
import "./css/PicaBookPrivacyPolicy.css";

const PicaBookPrivacyPolicy = ({ finalAppName }) => {
  return (
    <div>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We welcome you to PicaBook: Picture Book Creator, our website and/or
          our iOS and Android Mobile application. We are committed to protecting
          your Personal Data and to providing clear and transparent disclosures
          about the types of information we collect and how we use it.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ textAlign: "justify", lineHeight: "normal" }}
      >
        <span lang="en">
          For your further visits to our website and App, we would like to
          inform you about data collection, processing and use when visiting and
          using our website and App. As well as objection, revocation, and other
          rights to which you are entitled as a person affected by data
          collection and use.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          In principle, we will only use your Personal Data in accordance with
          applicable data protection laws, in particular the General Data
          Protection Regulation (“GDPR”), and only as described in this Privacy
          Policy.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">General Information</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">What is Personal Data?</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Personal Data is any information relating to personal or material
          circumstances that relates to an identified or identifiable
          individual. This includes, for example, your name, date of birth,
          e-mail address, postal address, or telephone number as well as online
          identifiers such as your IP address and device ID.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">What is processing?</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          "Processing" means any operation or set of operations which is
          performed upon Personal Data, whether or not by automatic means. The
          term is broad and covers virtually any handling of data.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Responsible for data processing</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Responsible for data processing is MyFortuna, Kostiantyn Makrasnov of
          708 E Sanson Ave. Spokane, WA USA (“we”, “us”, or “our”). If you have
          any questions or if you wish to exercise your rights, please contact
          us using support@myfortuna.app.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">The Legal Bases for processing Personal Data</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          In accordance with the GDPR, we have to have at least one of the
          following legal bases to process your Personal Data: a) you have given
          your consent, b) the data is necessary for the fulfillment of a
          contract / pre-contractual measures, c) the data is necessary for the
          fulfillment of a legal obligation, or d) the data is necessary to
          protect our legitimate interests, provided that your interests are not
          overridden.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">&nbsp;</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">Processing of Automatically Collected Data</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Log data</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Each time you visit our website, our system automatically records data
          from the visiting device and stores it in a so-called log file. Among
          other purely technical information, this includes both device
          information and IP address (identification of the user's device). The
          legal basis for processing is our legitimate interest.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Hosting </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          The hosting service used by us for the purpose of operating our
          website is Firebase Hosting by{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Google</span>
          </a>
          . In doing so Google processes all data to be processed in connection
          with the operation of our website, including log files on our behalf.
          The legal bases for processing are our legitimate interests, the
          provision of contractual services and the conclusion of the contract
          for our services.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">&nbsp;</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Cookies</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We use so-called cookies on our website. Cookies are pieces of
          information that are transmitted from our web server or third-party
          web servers to your web browser and stored there for later retrieval.
          Cookies may be small files or other types of information storage.
          There are different types of cookies:<i> </i>i)<i> </i>Essential
          Cookies<i>. </i>Essential cookies are cookies to provide a correct and
          user-friendly website; and ii) Non-essential Cookies<i>. </i>
          Non-essential Cookies are any cookies that do not fall within the
          definition of essential cookies, such as cookies used to analyze your
          behavior on a website (“analytical” cookies) or cookies used to
          display advertisements to you (“advertising” cookies).
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          As set out in the Privacy and Electronic Communications Directive
          (“PECD”), we need to obtain consent for the use of Non-essential
          Cookies. The legal basis for processing is our legitimate interest and
          your consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Cookie consent </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Our website uses a cookie consent tool to obtain your consent to the
          storage of cookies and to document this consent. When you enter our
          website, the following Personal Data is transferred to us: a) Your
          consent(s) or revocation of your consent(s); b) Your IP address; c)
          Information about your browser; d) Information about your device; d)
          Time of your visit to our website. The basis for processing is our
          legitimate interest and your consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            e)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Analytics</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We use Google Analytics, a web analytics service provided by Google on
          our website. Google Analytics also uses cookies to enable our website
          to analyze how users use our website across multiple devices. The
          information generated by the cookies about your use of our website is
          transmitted to and stored by Google. The following data is processed
          through the use of Google Analytics: a) 3 bytes of the IP address of
          the called system of the website visitor (anonymised IP address), b)
          the website called up, c) the website from which the user reached the
          accessed page of my website (referrer), d) the subpages accessed from
          the website, e) the time spent on the website, f) the frequency with
          which the website is accessed. Google states that it will not
          associate your IP address with any other data held by Google. The use
          of this service is based on your consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">&nbsp;</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            f)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Downloading and Installing the APP</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          The APP can be downloaded from the "Google Playstore" a service
          offered by Google, or the Apple App service "App Store" a service of{" "}
          <a
            href="https://www.apple.com/legal/privacy/en-ww/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Apple Inc</span>
          </a>
          . To install our APP. Downloading it may require prior registration
          with the respective App store and/or installation of the respective
          App store software.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">&nbsp;</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          As far as we are aware, Google collects and processes the following
          data: a) License check, b) network access, c) network connection, d)
          WLAN connections, and e) location information. However, it cannot be
          ruled out that Google also transmits the information to a server in a
          third country. We cannot influence which personal data Google
          processes with your registration and the provision of downloads in the
          respective App store and App store software. The responsible party in
          this respect is solely Google as the operator of the Google Play
          Store.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          As far as we are aware, Apple collects and processes the following
          data: a) device identifiers, b) IP addresses, and c) location
          information, it cannot be excluded that Apple also transmits the
          information to a server in a third country. We cannot influence which
          personal data Apple processes with your registration and the provision
          of downloads in the respective app store and app store software. The
          responsible party in this respect is solely Apple as the operator of
          the Apple App Store.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">&nbsp;</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Google and Apple may collect information from and about the device(s)
          you use to access the App, including hardware and software information
          such as a) IP address, b) device ID and type, c) device-specific and
          App settings and properties, d) APP crashes, advertising IDs (AAID),
          e) information about your wireless and mobile network connection such
          as your service provider and signal strength; f) information about
          device sensors such as accelerometer, gyroscope, and compass.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            g)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Authorizations and Access</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We may request permission to store your App data including your
          Internet Connection and Network, IP Address Location, Push
          Notifications. The legal basis for data processing is our legitimate
          interest and the provision of contractual or pre-contractual measures.
          You can deny access on your device via the Settings/Notifications/
          options of your device; however, this means that our App may not
          function as intended. The basis for processing is our legitimate
          interest and your consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            h)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Push messages</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          When you use our App, you will receive so-called push messages from
          us, even if you are not currently using our App. These are messages
          that we send you as part of the performance of the contract. You can
          adjust or stop receiving push messages at any time via the device
          settings of your device. Insofar as you consent to the use of push
          messages, consent is the legal basis for the processing.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            i)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Purchases </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          When you make In-app purchases, we (Google and Apple on our behalf)
          may collect the following data from you to process the purchase: a)
          Apple or Google user ID, b) Email address, c) Payment confirmation
          from the payment data collected by Apple or Google; and d) Device IP
          and device serial number to link the story history to the device. The
          legal basis for the provision of a payment system is the establishment
          and implementation of the user contract for the use of the App.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            j)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Advertising</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Our App uses the{" "}
          <a
            href="https://support.google.com/admob/answer/6128543?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Google Admob</span>
          </a>{" "}
          service to display ads. Ads are personalized based on the device you
          are using. You can disable this via the settings on your device.
          Google may use the advertising ID of your device, as well as cookies
          and/or similar technologies, to collect personal data for the purpose
          of generating and displaying personalized advertising. More detailed
          information about what data Google collects and how it is processed
          can be found{" "}
          <a
            href="https://www.google.com/policies/technologies/ads"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>here</span>
          </a>
          .
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">&nbsp;</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Advertisers and third parties also may collect information about your
          activity on our App, on devices associated with you, and on
          third-party sites and applications using tracking technologies.
          Tracking data collected by these advertisers and third parties is used
          to decide which ads you see both on our App and on third-party sites
          and applications. If you do not wish to participate in our advertising
          personalisation or retargeting/tracking you can object to behavioral
          advertising at the following websites:{" "}
          <a
            href="https://www.youronlinechoices.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Your Online Choices</span>
          </a>
          ,{" "}
          <a
            href="https://youradchoices.ca/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Digital Advertising Alliance of Canada</span>
          </a>
          <span>, </span>
          <a
            href="https://optout.networkadvertising.org/?c=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Network Advertising Initiative</span>
          </a>{" "}
          or{" "}
          <a
            href="https://youradchoices.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>AdChoices</span>
          </a>
          . <i> </i>In addition, you may also choose to control targeted
          advertising you receive within applications by using the settings and
          controls on your devices and/or use the checkbox function displayed
          when you launch our App. The basis for processing is our legitimate
          interest.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            k)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Firebase</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We use the Google{" "}
          <a
            href="https://firebase.google.com/support/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Firebase</span>
          </a>{" "}
          developer App and related features and services provided by Google. We
          use the following Google Firebase services in our Platform: a)
          Firebase Analytics, b) Firebase Crashlytics, c) Firebase Firestore,
          and d) Firebase Cloud Messaging for Push Notifications. By integrating
          Google services, Google may collect and process information (including
          personal data). It cannot be excluded that Google also transfers the
          information to a server in a third country. We cannot influence which
          data Google collects and processes. Firebase's key security and
          privacy information can be found here:{" "}
          <a
            href="https://firebase.google.com/support/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>https://firebase.google.com/support/privacy</span>
          </a>{" "}
          The legal basis is the implementation of the user contract for the use
          of the App.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            l)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Google Analytics for Firebase</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Our App uses the web analytics service Google Analytics for Firebase,
          which uses tracking technologies to track your use of our App. In this
          respect, information is generated about, among other things, the
          number of users and their sessions, the session duration, the
          operating system used by the users, their device model, the region
          from which our App is accessed, the first start of our App, our App
          execution and any updates.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          In order to provide the relevant data for analysis, Firebase Analytics
          uses your a) device's advertising ID, b) an App instance ID (a
          randomly generated number that identifies a single app installation),
          c) and the IP address, which is shortened (IP masking) before being
          processed on Google's servers (which may be located outside the
          EU/EEA) to generate the usage analysis. You can object to the use of
          Firebase Analytics at any time by disabling the sending of usage
          statistics in your device settings (Reset Advertising ID). We have no
          influence on these data processing operations.The basis for processing
          is our legitimate interest and your consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">Data processing by us</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Contacting us</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          If you contact us, we process the following data from you for the
          purpose of processing and handling your request: first name, last
          name, e-mail address, and, if applicable, other information if you
          have provided it, and your message. The legal basis for the data
          processing is our obligation to fulfill the contract and/or to fulfill
          our pre-contractual obligations and/or our legitimate interest in
          processing your request.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Account Registration </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          If you register, we will request mandatory and, where applicable,
          non-mandatory data in accordance with our registration form (Full
          Name, Email Address, and chosen Password). The entry of your data is
          encrypted so that third parties cannot read your data when it is
          entered. The basis for this storage is our legitimate interest and to
          fulfill our contractual obligations.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Alternatively, you are able to sign up using the convenience login and
          sign up from Google and Apple. For Google or Apple log in and sign up,
          you will be asked to provide your basic information (i.e., name, email
          address, and display picture) linked to your account. When registering
          via Google`s or Apple`s connect function, you agree to the relevant
          terms and conditions and consent to certain data from your respective
          profile being transferred to us.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">When using our services</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We process the data of our registered users in order to be able to
          provide our services as well as to ensure the security of our services
          and to be able to develop it further. This includes in particular our
          support, correspondence with you and to backup your books and to sync
          your books across devices. You may also share your books through
          in-app codes, website links or inside the book hub. Each book may
          include images, audio recordings, drawings, text or other content with
          personal information. Accordingly, the data is processed on the basis
          of fulfilling our contractual obligations as well as to fulfill our
          legal obligations. Additionally, some of the data you choose to
          provide may be considered non-Personal Data and/or “special” or
          “sensitive” in certain jurisdictions, for example your racial or
          ethnic origins, and religious beliefs. By choosing to provide any of
          this data, you consent to our processing of that data. The legal basis
          for the data processing is the fulfillment of our contractual
          obligations and, in individual cases, the fulfillment of our legal
          obligations as well as your or parental consent.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">General Principles</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Minors</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          In accordance with Art. 8 of the GDPR, the processing of Personal Data
          relating to a person younger than 16 years requires the consent of a
          parent or legal guardian. Further and in line with the US Children's
          Online Privacy Protection Act of 1998 (COPPA) operators of websites
          and online services are required to protect the privacy of children
          under the age of 13 years. Specifically, both the GDPR and
          COPPA&nbsp;stipulate that a) we must seek and
          require&nbsp;verifiable&nbsp;parental consent; b) we must provide an
          appropriate privacy policy, and c) clearly set out our
          responsibilities regarding children's privacy and safety online.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We allow users of all ages to use the app, however, we do not collect
          any personal information from users under the age of 13 (US only) or
          16 (EU only) unless we are given verifiable parental consent. If you
          suspect that a user is a minor, please report it to us as soon as
          possible.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Data storage</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          In general, your data and content of your books is saved and stored on
          our Firebase server. We store your data and content of your books in
          encrypted format and it can only be accessed when you have the account
          access or you have permitted the sharing. The data you enter into your
          books is not accessible by us nor any other third party. You can
          export and delete your book at any time by using the relevant
          functions in our App. The legal basis is consent and of course, the
          contract for the use of our services.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Automated decision-making</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Automated decision-making including profiling does not take place.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Do Not Sell</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">We do not sell your Personal Data.</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">&nbsp;</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            e)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Sharing</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We will not disclose or otherwise distribute your Personal Data to
          third parties unless this is a) necessary for the performance of our
          services, b) you have consented to the disclosure, c) or the
          disclosure of data is permitted by relevant legal provisions. In
          addition, we may disclose your Personal Data: in connection with law
          enforcement, fraud prevention or other legal proceedings; as required
          by law or regulation; or if we have reason to believe that disclosure
          is necessary to protect us and our App.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            f)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">International Transfer</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We usually do not transfer Personal Data, however, if we do we ensure
          that processing is governed by Processing Agreements that include
          Standard Contractual Clauses to ensure a high level of data
          protection.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            g)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Data Security</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Our data processing is subject to the principle that we only process
          the Personal Data that is necessary for the use of our services. In
          doing so, we take great care to ensure that your privacy and the
          confidentiality of all Personal Data are always guaranteed.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          Nonetheless, databases or data sets that include Personal Data may be
          breached inadvertently or through wrongful intrusion. Upon becoming
          aware of a data breach, we will notify all affected individuals whose
          Personal Data may have been compromised, and the notice will be
          accompanied by a description of the action being taken to reconcile
          any damage as a result of the data breach. Notices will be provided as
          expeditiously as possible after which the breach was discovered.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">Your Rights and Privileges </span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            a)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Privacy rights </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">You can exercise the following rights:</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right to information</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right to rectification</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right to object to processing</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right to deletion</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right to data portability</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right of objection</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right to withdraw consent</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">Right to complain to a supervisory authority</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".5in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <span lang="en" style={{ fontFamily: '"Noto Sans Symbols"' }}>
          ●
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <i>
          <span lang="en">
            Right not to be subject to a decision based solely on automated
            processing.
          </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">&nbsp;</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          If you wish to exercise any of your rights, please contact us.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            b)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Updating your information</span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          If you believe that the information we hold about you is inaccurate or
          that we are no longer entitled to use it and want to request its
          rectification, deletion, or object to its processing, please do so by
          contacting us.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            c)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Withdrawing your consent </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          You can revoke consents you have given at any time by contacting us.
          The legality of the data processing carried out until the revocation
          remains unaffected by the revocation.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "0in",
          marginLeft: ".25in",
          textAlign: "justify",
          textIndent: "-.25in",
          lineHeight: "normal",
        }}
      >
        <i>
          <span lang="en">
            d)
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;
            </span>
          </span>
        </i>
        <i>
          <span lang="en">Access Request </span>
        </i>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          In the event that you wish to make a Data Subject Access Request, you
          may inform us in writing of the same. We will respond to requests
          regarding access and correction as soon as reasonably possible. Should
          we not be able to respond to your request within thirty (30) days
          after receiving your request, we will inform you in writing within
          thirty (30) days of the time by which we will be able to respond to
          your request. If we are unable to provide you with any Personal Data
          or to make a correction requested by you, we shall generally inform
          you of the reasons why we are unable to do so (except where we are not
          required to do so under the respective legal regulations mentioned
          above).
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">Updates</span>
        </b>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <span lang="en">
          We may update this policy from time to time. If we make changes, we
          will revise the effective date at the end of this section. If you have
          any questions or if you wish to exercise your rights, please contact
          us using support@myfortuna.app. We encourage you to periodically
          review this policy to be informed of how we use and protect your
          Personal Data. This policy was last updated on Monday, 23rd of
          October, 2023.
        </span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{ marginBottom: "0in", lineHeight: "115%" }}
      >
        <span lang="en">&nbsp;</span>
      </p>
      <p
        className="MsoNormal"
        style={{
          marginBottom: "0in",
          textAlign: "justify",
          lineHeight: "normal",
        }}
      >
        <b>
          <span lang="en">&nbsp;</span>
        </b>
      </p>
      <p className="MsoNormal">
        <span lang="en">&nbsp;</span>
      </p>
    </div>
  );
};

export default PicaBookPrivacyPolicy;
