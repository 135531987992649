import PhoneScreenshot from "../../views/PhoneScreenshot/PhoneScreenshot";

const SingleEnlarged = ({ screenshots, containerStyle }) => {
  return (
    <div
      style={{
        width: "100%",
        minWidth: 0,
        display: "flex",
        justifyContent: "center",
        ...containerStyle,
      }}
    >
      <PhoneScreenshot
        screenshot={screenshots[0]?.src}
        alt={screenshots[0]?.alt}
      />
    </div>
  );
};

export default SingleEnlarged;
