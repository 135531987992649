import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./generic/components/views/LandingPage/LandingPage";
import { apps } from "./apps/apps";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { mergeGenericAppConfig } from "./apps/mergeGenericAppConfig";
import PolicyPage from "./pages/PolicyPage";
import PrivacyPolicy from "./generic/components/views/PrivacyPolicy";
import TermsOfService from "./generic/components/views/TermsOfService";
import ContactPageWrapped from "./pages/ContactPage/ContactPageWrapped";
import ListAppsPage from "./pages/ListAppsPage/ListAppsPage";
import PicaBookPrivacyPolicy from "./generic/components/views/PicaBookPrivacyPolicy";

function App() {
  const theme = useTheme({
    palette: {
      light: {
        main: "#ffffff",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/"}
            element={
              <div id={"home_page"}>
                <ListAppsPage
                  key={"listAppsPage"}
                  tabTitle={"My Fortuna Apps"}
                  pageTitle={"Welcome to My Fortuna Apps"}
                  subTitle={
                    "You might want to explore some of our current projects below."
                  }
                />
              </div>
            }
          />
          {Array.isArray(apps) &&
            apps.length > 0 &&
            apps.map((app, index) => {
              return (
                <Route
                  exact
                  path={app?.path}
                  element={<LandingPage app={mergeGenericAppConfig({ app })} />}
                  key={app?.path || index}
                />
              );
            })}
          <Route
            exact
            path={"/contact"}
            element={<ContactPageWrapped userContext={"user"} />}
          />
          <Route
            exact
            path={"/businessInquiry"}
            element={
              <ContactPageWrapped
                userContext={"business"}
                headlineMessage={
                  "We are looking forward to working with you on a new mobile app project at MyFortuna. Please contact us with your idea or proposal."
                }
              />
            }
          />
          {Array.isArray(apps) &&
            apps.length > 0 &&
            apps.map((app, index) => {
              const isPicaBook = app?.name === "PicaBook";
              return (
                <Route
                  exact
                  path={"/privacyPolicy" + app?.path}
                  element={
                    <PolicyPage
                      app={app}
                      policyName={"Privacy Policy"}
                      policyContent={
                        isPicaBook ? (
                          <PicaBookPrivacyPolicy />
                        ) : (
                          <PrivacyPolicy />
                        )
                      }
                    />
                  }
                  key={"/privacyPolicy" + app?.path}
                />
              );
            })}
          {Array.isArray(apps) &&
            apps.length > 0 &&
            apps.map((app, index) => {
              return (
                <Route
                  exact
                  path={"/termsOfService" + app?.path}
                  element={
                    <PolicyPage
                      app={app}
                      policyName={"Terms of Service"}
                      policyContent={<TermsOfService />}
                    />
                  }
                  key={"/termsOfService" + app?.path}
                />
              );
            })}
          <Route
            path={"*"}
            element={
              <div id={"not_found_page"}>
                <ListAppsPage
                  key={"notFoundPage"}
                  tabTitle={"404 - Not Found"}
                  pageTitle={"Oops... Page Not Found"}
                  subTitle={
                    "You might want to explore some of MyFortuna's apps."
                  }
                  noindex={true}
                />
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
