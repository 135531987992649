import Footer from "../../generic/components/views/Footer/Footer";
import MetaBlocks from "../../generic/components/views/MetaBlocks";
import ContactTextField from "../../generic/components/inputs/text/ContactTextField";
import {useEffect, useRef, useState} from "react";
import LinkIconButton from "../../generic/components/inputs/buttons/LinkIconButton";
import {getFunctions, httpsCallable} from "firebase/functions";
import TitleSubtitle from "../../generic/components/template/text/TitleSubtitle";
import {Icon} from "@iconify/react/dist/iconify";
import "./ContactPage.scss";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import MainTitleSubtitle from "../../generic/components/template/text/MainTitleSubtitle";

const ContactPage = ({headlineMessage, userContext}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isSending, setIsSending] = useState(false);
  const [sendResult, setSendResult] = useState(null);
  const [completed, setCompleted] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmitForm = async () => {
    //Check if reCaptcha passes
    let recaptchaValue;
    try {
      if(!executeRecaptcha) {
        throw Error("Recaptcha not initialized!")
      }
      recaptchaValue = await executeRecaptcha('submit_contact_form');
    } catch (e) {
      setSendResult({
        status: 400,
        message: "Couldn't verify client, please try again in a different browser...",
      });
      return;
    }

    //Check the input
    if (!email || !email.includes("@")) {
      setSendResult({
        status: 400,
        message: "Please enter a valid email address.",
      });
      return;
    }

    if (!message || message.length < 3) {
      setSendResult({
        status: 400,
        message: "Please enter a message that is longer than 3 characters.",
      });
      return;
    }

    if (!recaptchaValue) {
      setSendResult({
        status: 400,
        message: "Please complete the reCAPTCHA.",
      });
      return;
    }

    const input = {
      firstName,
      lastName,
      email,
      message,
      recaptchaValue,
      userContext,
    };

    try {
      setIsSending(true);
      const functions = getFunctions();
      const contactRequest = httpsCallable(functions, "contactRequest");
      const result = await contactRequest(input);
      if (!result || !result.data) {
        throw new Error("No result returned from contactRequest");
      }

      if (result.data.status === 200) {
        setCompleted(true);
      }
      setSendResult(result.data);
    } catch (e) {
      console.error(e);
      setSendResult({
        status: 500,
        message:
          "An error occurred while sending your message. Please try again later.",
      });
    }
    setIsSending(false);
  };

  if (!headlineMessage) {
    headlineMessage =
      "At MyFortuna we strive to provide you with only the best services and products. Please contact us with any questions or concerns you may have.";
  }

  return (
    <div
      style={{
        backgroundImage: "radial-gradient(circle at 70% 20%, #602AFF, #0D084D)",
        minHeight: "100vh",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MetaBlocks/>
      <div
        className={"contactPageContentContainer"}
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {/*Headline*/}
        <div
          className={"contactPageHeadlineContainer"}
          style={{
            margin: "2em",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={"contactPageVerticalSpacer"} style={{flex: 3}}/>
          <div className={"contactPageHeadlineWrapper"}>
            <MainTitleSubtitle titles={[headlineMessage]}/>
          </div>
          <div sclassName={"contactPageVerticalSpacer"} style={{flex: 6}}/>
        </div>
        {/*Form*/}
        {completed ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              style={{color: "white", fontSize: "7em"}}
              icon={"fluent-mdl2:completed-solid"}
            />
            <TitleSubtitle
              titles={["Thank you!"]}
              subtitles={["Your message has been sent."]}
              textStyle={{color: "white", textAlign: "center"}}
            />
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "2em",
                color: "white",
                fontFamily: "Roboto",
                padding: 0,
                margin: 0,
                marginLeft: ".5em",
              }}
            >
              {" "}
              Contact Form:{" "}
            </p>
            <div style={{display: "flex", flexDirection: "row"}}>
              <ContactTextField
                onChange={(e) => setFirstName(e.target.value)}
                label={"First Name"}
                style={{flex: 1, margin: "1em"}}
              />
              <ContactTextField
                onChange={(e) => setLastName(e.target.value)}
                label={"Last Name"}
                style={{flex: 1, margin: "1em"}}
              />
            </div>
            <ContactTextField
              label={"Email"}
              style={{margin: "1em"}}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
            <ContactTextField
              onChange={(e) => setMessage(e.target.value)}
              label="Request"
              multiline={true}
              minRows={6}
              maxRows={10}
              required={true}
              style={{margin: "1em", marginTop: 0}}
            />
            <div
              id={"after-recaptcha-container"}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "20em",
                  margin: "1em",
                }}
              >
                <LinkIconButton
                  icon={
                    isSending
                      ? "eos-icons:loading"
                      : "material-symbols:send-rounded"
                  }
                  text={isSending ? "Sending..." : "Send Message"}
                  disabled={isSending}
                  onClick={onSubmitForm}
                />
              </div>
              {sendResult && (
                <p
                  style={{color: sendResult.status === 200 ? "green" : "red"}}
                >
                  {sendResult.message}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <Footer/>
    </div>
  );
};

export default ContactPage;
