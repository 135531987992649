import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Backdrop } from "@mui/material";
import { useWindowSize } from "../../../hooks/useWindowSize";

const ModalVideo = ({ videos }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Button
        onClick={() => setIsOpen(true)}
        variant="text"
        sx={{
          color: "white",
          borderColor: "white",
          "&:hover": {
            color: theme?.palette?.light?.main || "white",
            transform: "scale(1.1)",
            transition: "transform 0.5s ease-in-out",
          },
        }}
      >
        <Icon
          icon="mdi:play"
          style={{ fontSize: "2em", marginRight: ".5em" }}
        />
        Play Video
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
        onClick={() => setIsOpen(false)}
      >
        <div
          style={{
            width: "90%",
            height: "90%",
            aspectRatio: "16/9",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isOpen && (
            <iframe
              width={"100%"}
              height={"100%"}
              src={videos[0] + "?autoplay=1"}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Backdrop>
    </div>
  );
};

export default ModalVideo;
