import React from 'react';
import TitleSubtitle from "./TitleSubtitle";

const Header = ({ type, texts, textStyle }) => {

  return <TitleSubtitle
    titles={texts}
    textStyle={textStyle}
  />

}

export default Header;