import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { apps } from "../../apps/apps";

export const usePageTitle = () => {
  //TODO: set a custom icon for the tab as well

  const location = useLocation();
  useEffect(() => {
    const pathname = location?.pathname;
    if (!pathname) {
      return;
    }

    if (pathname === "/") {
      document.title = "PicaBook";
      return;
    }

    //Find from other apps
    let routeName;
    if (Array.isArray(apps)) {
      const foundApp = apps.find((app) => {
        return app?.path === location?.pathname;
      });
      routeName = foundApp?.name;
    }

    document.title = routeName || "My Fortuna";
  }, [location?.pathname, apps]);
};
