import ContactPage from "./ContactPage";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const ContactPageWrapped = (props) => {
  return <GoogleReCaptchaProvider
    reCaptchaKey={"6LdTzeYkAAAAABE9n2wccJxXlfci0bWQHyhiXpe4"}
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
    <ContactPage {...props} />
  </GoogleReCaptchaProvider>
}

export default ContactPageWrapped;