import ReviewTile from "../../generic/components/views/ReviewTile/ReviewTile";
import IconTile from "../../generic/components/views/IconTile";
import { REVIEW_OVERLAY_PLACEMENT } from "../../generic/components/template/screenshot/ReviewOverlay";
import React from "react";
import { cloneDeep } from "lodash";

const subtitles = [
  "Create and share short stories with the world.",
  "The ultimate digital picture book creator.",
];

const introduction = [
  {
    type: "asset/image",
    images: ["picabook/assets/images/book_flying.png"],
    alt: "Flying Book Logo",
    imageStyle: {
      width: "20em",
    },
  },
  {
    type: "text/main_title_subtitle",

    verticalAlign: "center",
    horizontalAlign: "center",

    titles: ["PicaBook"],
    subtitles,
    textStyle: {
      textAlign: "center",
    },
  },
  {
    type: "asset/modal_video",
    videos: ["https://www.youtube.com/embed/w-b_J7YAw_Q"],
  },
];

const introduction_showcase = [
  {
    type: "screenshot/showcase_double_pivot",

    screenshots: [
      {
        src: "picabook/assets/screenshots/get_title_idea.png",
        alt: "Choose a title for your book screenshot",
      },
      {
        src: "picabook/assets/screenshots/create_start.png",
        alt: "Draw your first page screenshot",
      },
    ],
  },
];

const main_feature_descriptions = [
  {
    type: "layout/split_horizontal",
    leftFlex: 4,
    rightFlex: 6,
    contentLeft: [
      {
        type: "screenshot/single_enlarged",

        screenshots: [
          {
            src: "picabook/assets/screenshots/hub_books.png",
            alt: "Screenshot of a large library of public books",
          },
        ],
      },
    ],
    contentRight: [
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["Personal Library"],
        subtitles: ["Keep stories safely stored privately on your phone."],
      },
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["Public Book Hub"],
        subtitles: [
          "Explore an ever growing collection of stories made by other creators.",
        ],
      },
    ],
  },
];
const mobile_main_feature_descriptions = cloneDeep(main_feature_descriptions);
mobile_main_feature_descriptions[0].contentLeft[0].containerStyle = {
  justifyContent: "end",
};

// REVIEWS
const app_review_items = [
  {
    author: "Claudio Joao",
    message:
      "Very good, my daughter is addicted! She's making up stories all day! It distracts the mind a lot so I recommend parents, to their children.",
    rating: 5,
  },
  {
    author: "Angela Muine",
    message:
      "It is a great app. This app is the best picture book app you can find 😍😍😍",
    rating: 5,
  },
  {
    author: "Keith Pryor",
    message: "I love it u can create your very own books!",
    rating: 5,
  },
  {
    author: "Qzee Wardaya",
    message:
      "+ (Positive) + Can make illustrated stories + Text and voice features + Can choose colors + Can share book codes + No ads + 100% Free + Offline",
    rating: 5,
  },
];

const app_review_grid = [
  {
    type: "text/header",
    texts: ["See what others have to say:"],
    textStyle: {
      textAlign: "center",
      fontSize: "4em",
    },
  },
  {
    type: "layout/grid",
    itemsPerRow: 2,
    items: app_review_items,
    component: <ReviewTile />,
  },
];

const app_review_screenshots = [
  {
    type: "screenshot/review_overlay",
    screenshots: ["picabook/assets/screenshots/create_start.png"],
    reviews: [...app_review_items.slice(0, app_review_items.length / 2)],
    placement: REVIEW_OVERLAY_PLACEMENT.BOTTOM_LEFT,
  },
  {
    type: "screenshot/review_overlay",
    screenshots: ["picabook/assets/screenshots/create_add_stickers.png"],
    reviews: [...app_review_items.slice(app_review_items.length / 2)],
    placement: REVIEW_OVERLAY_PLACEMENT.TOP_RIGHT,
  },
];

const feature_tile_grid = [
  {
    type: "text/title_subtitle",
    titles: ["You want it? We got it!"],
    subtitles: ["Fill your pages with any content you would like"],
    textStyle: {
      textAlign: "center",
    },
    titleStyle: {
      fontSize: "4em",
    },
    subtitleStyle: {
      fontSize: "2em",
    },
  },
  {
    type: "layout/grid",
    itemsPerRow: 3,
    maxItemsPerRowMobile: 3,
    minItemSizeEM: 21,
    items: [
      {
        icon: "material-symbols:text-fields-rounded",
        color: "#F9931F",
        title: "Text",
      },
      {
        icon: "material-symbols:music-note-rounded",
        color: "#FF4880",
        title: "Music",
      },
      {
        icon: "mdi:microphone-variant",
        color: "#FF4880",
        title: "Voice",
      },
      {
        icon: "bi:images",
        color: "#537BFF",
        title: "Stickers",
      },
      {
        icon: "material-symbols:camera-enhance-rounded",
        color: "#537BFF",
        title: "Photos",
      },
      {
        icon: "mdi:draw",
        color: "#58BDBA",
        title: "Drawings",
      },
    ],
    gridStyle: { maxWidth: "80em" },
    component: <IconTile />,
  },
];

export const picabook_config = {
  name: "PicaBook",
  subtitles,
  backgroundColor: "#00e075",
  path: "/picabook",

  title: "PicaBook: The Best Digital Picture Book Creator",
  description:
    "Create & Share Picture Books with Ease on PicaBook! Perfect for long car rides & bed time stories. Best app for developing creativity and imagination.",
  keywords:
    "Create Picture Books, Develop Creativity, Find Short Stories, Best Drawing App, Share bedtime story, PicaBook, Explore Kid Creations",

  googlePlayLink:
    "https://play.google.com/store/apps/details?id=com.myfortuna.picabook",
  appStoreLink:
    "https://apps.apple.com/us/app/picabook-picture-book-creator/id1643338958",

  socialLinks: [
    {
      type: "youtube",
      url: "https://www.youtube.com/channel/UC0Rv78x2vaOf4R_0J5K6V9w",
    },
  ],

  theme: {
    palette: {
      type: "light",
    },
  },

  sections: [
    {
      type: "layout/split_horizontal",
      leftFlex: 4,
      rightFlex: 6,
      contentLeft: [...introduction],
      contentRight: [...introduction_showcase],
    },

    ...main_feature_descriptions,

    ...app_review_grid,

    ...feature_tile_grid,
  ],

  mobileSections: [
    ...introduction,

    ...mobile_main_feature_descriptions,

    ...app_review_screenshots,

    ...feature_tile_grid,
  ],
};
