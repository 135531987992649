import PhoneScreenshot from "../../views/PhoneScreenshot/PhoneScreenshot";
import ReviewTile from "../../views/ReviewTile/ReviewTile";

export const REVIEW_OVERLAY_PLACEMENT = {
  TOP_LEFT: "top_left",
  TOP_RIGHT: "top_right",
  BOTTOM_LEFT: "bottom_left",
  BOTTOM_RIGHT: "bottom_right",
};

const ReviewOverlay = ({
  reviews,
  screenshots,
  placement = REVIEW_OVERLAY_PLACEMENT.BOTTOM_LEFT,
}) => {
  const containerStyle = {};
  const positionOffsets = {};
  if (
    placement === REVIEW_OVERLAY_PLACEMENT.TOP_LEFT ||
    placement === REVIEW_OVERLAY_PLACEMENT.TOP_RIGHT
  ) {
    positionOffsets.top = "15em";
  } else {
    positionOffsets.bottom = "15em";
  }
  if (
    placement === REVIEW_OVERLAY_PLACEMENT.TOP_LEFT ||
    placement === REVIEW_OVERLAY_PLACEMENT.BOTTOM_LEFT
  ) {
    positionOffsets.left = "0em";
    containerStyle.borderRadius = "0em 1em 1em 0em";
  } else {
    positionOffsets.right = "0em";
    containerStyle.borderRadius = "1em 0em 0em 1em";
  }

  return (
    <div style={{ position: "relative" }}>
      <PhoneScreenshot screenshot={screenshots[0]} scale={1.5} />
      <div
        style={{
          position: "absolute",
          ...positionOffsets,
          width: "50%",
          minWidth: "15em",
        }}
      >
        <ReviewTile item={{ ...reviews[0], containerStyle }} />
      </div>
    </div>
  );
};

export default ReviewOverlay;
