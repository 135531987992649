import React from "react";
import {Icon} from "@iconify/react/dist/iconify";

const StarRating = ({rating}) => {

  const stars = [];
  for (let i = 0; i < 5; i++) {
    if ((rating - i) >= 1) {
      stars.push(<Icon icon="material-symbols:star" style={{fontSize: "2em", marginRight: ".5em"}}/>)
    } else if ((rating - i) > .5) {
      stars.push(<Icon icon="material-symbols:star-half" style={{fontSize: "2em", marginRight: ".5em"}}/>)
    } else {
      stars.push(<Icon icon="material-symbols:star-outline" style={{fontSize: "2em", marginRight: ".5em"}}/>)
    }
  }

  return <div
    style={{
      display: "flex",
      flexDirection: "row"
  }}>
    {
      stars.map((star, index) => {
        return <div key={index}>{star}</div>
      })
    }
  </div>
}

export default StarRating;