import {Icon} from "@iconify/react/dist/iconify";
import React from "react";
import {Paper} from "@mui/material";

const IconTile = ({item}) => {
  const {icon, color, title} = item;
  return (
    <div>
      <Paper
        style={{
          backgroundColor: color,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "1em"
        }}>
        <div style={{width: "70%", display: "flex", justifyContent: "center"}}>
          <Icon icon={icon} style={{fontSize: "13em", color: "white"}} alt={title + " icon"}/>
        </div>
      </Paper>
      {title && <div>
        <p style={{
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: "2em",
          color: "white",
          margin: 0,
          marginTop: ".3em",
          textAlign: "center"
        }}>
          {title}
        </p>
      </div>}
    </div>


  );
}

export default IconTile;