import React from "react";
import AppListEntry from "./components/AppListEntry";
import {apps} from "../../../../apps/apps";

const AppList = () => {

  return <div style={{display: "flex", flexDirection: "column"}}>
    {
      apps.map((app, index) => {
        return <AppListEntry
          app={app}
          key={index}
        />
      })
    }
  </div>
}

export default AppList;