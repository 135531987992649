export const LinkImageButton = ({ link, image, alt }) => {
  return (
    <a href={link} style={{ height: "100%" }}>
      <img
        src={image}
        alt={alt}
        style={{ maxHeight: "100%", maxWidth: "100%" }}
      />
    </a>
  );
};

export default LinkImageButton;
