import googleImage from "../../../assets/images/get_it_on_google_play_cropped.png";
import appleImage from "../../../assets/images/download_on_the_app_store_cropped.png";
import LinkImageButton from "../inputs/buttons/LinkImageButton";
import LinkIconButton from "../inputs/buttons/LinkIconButton";
import { useWindowSize } from "../../hooks/useWindowSize";

const DownloadHeader = ({ app }) => {
  const { isMobile } = useWindowSize();

  const textStyle = {
    padding: 0,
    margin: 0,
    color: "white",
    marginRight: "1em",
    fontSize: "2em",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: ".5em",
        padding: ".5em",
        position: "fixed",
        top: 0,
        width: "100%",
        height: "3em",
        zIndex: 1000,
        backgroundColor: app?.backgroundColor,
      }}
    >
      {!isMobile && <p style={{ ...textStyle }}>Interested?</p>}
      {app?.googlePlayLink && (
        <LinkImageButton
          image={googleImage}
          link={app?.googlePlayLink}
          alt={"Get it on Google Play"}
        />
      )}
      {app?.appStoreLink && (
        <LinkImageButton
          image={appleImage}
          link={app?.appStoreLink}
          alt={"Download on the App Store"}
        />
      )}
      {!isMobile && (app?.googlePlayLink || app?.appStoreLink) && (
        <p
          style={{
            ...textStyle,
            marginLeft: "1em",
            marginRight: "1em",
            fontSize: "1.5em",
          }}
        >
          OR
        </p>
      )}
      {(!isMobile || (!app?.googlePlayLink && !app?.appStoreLink)) && (
        <LinkIconButton
          link={"/contact/"}
          icon={"mdi:email"}
          text={"Contact"}
          iconStyle={{ fontSize: "1.5em" }}
          textStyle={{ fontSize: "1.5em" }}
        />
      )}
    </div>
  );
};

export default DownloadHeader;
