import { generic_config } from "./generic/generic_config";

export const mergeGenericAppConfig = ({ app }) => {
  const finalConfig = {
    ...generic_config,
    ...app,
  };

  // use array merge for socialLinks, use type as the primary key
  const newSocialLinks = [...generic_config.socialLinks];
  if (Array.isArray(app.socialLinks)) {
    app.socialLinks.forEach((socialLink) => {
      const index = newSocialLinks.findIndex(
        (newSocialLink) => newSocialLink.type === socialLink.type
      );
      if (index > -1) {
        newSocialLinks[index] = socialLink;
      } else {
        newSocialLinks.push(socialLink);
      }
    });
  }
  finalConfig.socialLinks = newSocialLinks;

  return finalConfig;
};
