import React from 'react';
import {useDynamicImage} from "../../../hooks/useDynamicImage";

const Image = ({images, imageStyle, alt}) => {

  const currentImage = useDynamicImage({src: images[0]});

  return <div style={{display: "flex", justifyContent: "center"}}>
    <img
      src={currentImage}
      title={images[0].split("/").splice(-1)[0]}
      style={{width: "100%", ...imageStyle}}
      alt={alt}
    />
  </div>
}

export default Image;