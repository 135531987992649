import React from 'react';
import {Paper} from '@mui/material';
import StarRating from "./components/StarRating";

const ReviewTile = ({item}) => {

  const {containerStyle={}, author, message, rating} = item || {};

  return <Paper style={{borderRadius: "1em", ...containerStyle}} elevation={12}>
    <div
      style={{
        margin: "1em",
      }}
    >
      <p style={{
        fontFamily: "Roboto",
        fontWeight: 700,
        fontSize: "2em",
      }}>
        {author}
      </p>
      <p style={{
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "1.5em",
      }}>
        {message}
      </p>
      <StarRating rating={rating}/>
    </div>
  </Paper>
};

export default ReviewTile;