import {cloneDeep} from "lodash";

const subtitles = [
  "File transfer made easy with automatic jobs.",
  "Automate storage tasks, execute with a single tap.",
  "A faster data transfer utility for mobile."
];

const introduction = [
  {
    type: "text/main_title_subtitle",

    verticalAlign: "center",
    horizontalAlign: "center",

    titles: ["Tegra"],
    subtitles,
    textStyle: {
      textAlign: "center",
    },
  },
];

const introduction_showcase = [
  {
    type: "screenshot/showcase_double_pivot",


    screenshots: [
      {
        src: "tegra/assets/screenshots/job_list.png",
        alt: "Screenshot of a sample job list",
      },
      {
        src: "tegra/assets/screenshots/job_details.png",
        alt: "Screenshot of a single job details",
      },
    ],
  },
];

const main_feature_descriptions = [
  {
    type: "layout/split_horizontal",
    contentLeft: [
      {
        type: "screenshot/single_enlarged",

        horizontalAlign: "start",

        screenshots: [{
          src: "tegra/assets/screenshots/job_update.png",
          alt: "Screenshot of job transfer setting options"
        }],
      },
    ],
    contentRight: [
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["Save jobs to do later"],
        subtitles: [
          "A personal collection of saved file transfer settings always available.",
        ],
      },
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["Queue tasks and get notified when they're done"],
        subtitles: [
          "Intelligent background mode and progress tracking keeps you informed.",
        ],
      },
    ],
  },
];
const mobile_main_feature_descriptions = cloneDeep(main_feature_descriptions);
mobile_main_feature_descriptions[0].contentLeft[0].containerStyle = {
  justifyContent: "end",
};

export const tegra_config = {
  name: "Tegra",
  subtitles,
  backgroundColor: "#a01eea",
  path: "/tegra",

  title: "Terga: The Most Intuitive File Transfer App for Mobile",
  description:
    "Create reusable file transfer jobs on your mobile device with Terga. Explore advanced renaming options and background operation in a simple and intuitive UI.",
  keywords:
    "Automated Mobile File Transfer, Create File Transfer Jobs, Intuitive File Transfer Manager, Fastest Mobile File Transfer, Terga, 2023 File Transfer App",

  googlePlayLink: "https://play.google.com/store/apps/details?id=com.myfortuna.tegra",
  //appStoreLink: "https://apps.apple.com/us/app/picabook-picture-book-creator/id1643338958",

  sections: [
    {
      type: "layout/split_horizontal",
      leftFlex: 4,
      rightFlex: 6,
      contentLeft: [...introduction],
      contentRight: [...introduction_showcase],
    },
    ...main_feature_descriptions,
  ],
  mobileSections: [
    ...introduction,
    ...introduction_showcase,
    ...mobile_main_feature_descriptions,
  ],
};
