import SplitHorizontal from "./layout/SplitHorizontal";
import Grid from "./layout/Grid";
import Header from "./text/Header";
import TitleSubtitle from "./text/TitleSubtitle";
import React from "react";
import SingleEnlarged from "./screenshot/SingleEnlarged";
import ShowcaseDoublePivot from "./screenshot/ShowcaseDoublePivot";
import Image from "./asset/Image";
import ModalVideo from "./asset/ModalVideo";
import ReviewOverlay from "./screenshot/ReviewOverlay";
import MainTitleSubtitle from "./text/MainTitleSubtitle";

export const Template = ({ type, config }) => {
  let CurrentTemplate = null;

  switch (type) {
    case "layout/split_horizontal":
      CurrentTemplate = <SplitHorizontal />;
      break;
    case "layout/grid":
      CurrentTemplate = <Grid />;
      break;

    case "text/header":
      CurrentTemplate = <Header />;
      break;
    case "text/main_title_subtitle":
      CurrentTemplate = <MainTitleSubtitle />;
      break;
    case "text/title_subtitle":
      CurrentTemplate = <TitleSubtitle />;
      break;

    case "screenshot/single_enlarged":
      CurrentTemplate = <SingleEnlarged />;
      break;
    case "screenshot/showcase_double_pivot":
      CurrentTemplate = <ShowcaseDoublePivot />;
      break;
    case "screenshot/review_overlay":
      CurrentTemplate = <ReviewOverlay />;
      break;

    case "asset/image":
      CurrentTemplate = <Image />;
      break;
    case "asset/modal_video":
      CurrentTemplate = <ModalVideo />;
      break;
    default:
      return (
        <div
          style={{ width: "100%", height: "100%", backgroundColor: "red" }}
        >{`TODO: implement ${type} tempalate`}</div>
      );
  }

  return React.cloneElement(CurrentTemplate, config);
};
