import { Template } from "../Template";

const SplitHorizontal = ({
  leftFlex,
  rightFlex,
  contentLeft,
  contentRight,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: leftFlex || 1,
          maxWidth: ((leftFlex / rightFlex) * 100).toString() + "%",
          overflow: "hidden",
        }}
      >
        {Array.isArray(contentLeft) &&
          contentLeft.length > 0 &&
          contentLeft.map((item, index) => {
            return <Template key={index} type={item?.type} config={item} />;
          })}
      </div>
      <div style={{ flex: rightFlex || 1, marginLeft: ".5em" }}>
        {Array.isArray(contentRight) &&
          contentRight.length > 0 &&
          contentRight.map((item, index) => {
            return <Template key={index} type={item?.type} config={item} />;
          })}
      </div>
    </div>
  );
};

export default SplitHorizontal;
